import React from "react"
import {
  AppBar,
  Breadcrumb,
  Footer,
  Layout,
  SEO,
  SnsShare,
} from "../components"
import { Home } from "../views"

type Props = {}

const Component: React.FC<Props> = (props: Props) => {
  return (
    <Layout>
      <SEO />
      <AppBar />
      <Breadcrumb showNav={false} itemList={[{ name: "トップ", slug: "" }]} />
      <SnsShare />
      <Home />
      <Footer slug="/" copyright={{ publishDate: new Date() }} />
    </Layout>
  )
}

export default Component
